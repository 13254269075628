function transfer(obj,url){
    if(!typeof(obj)=="object"){
        throw "transfer use object of first msg!";
    }
    if(!url&&!typeof(url)=="string"){
        throw "transfer user string of two msg!";
    }
    let _url=url+"?"
    Object.keys(obj).forEach(e=>{
        _url+=e+'='+obj[e]+'&';
    })
    window.location = _url;
}
export default transfer;