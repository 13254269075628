<template>
  <div class="allmsgclass">
    <navigation msg="分享简历"></navigation>
    <div class="downimgclass">
      <img src="../../assets/downimg.png" alt="" />
    </div>
    <div class="tc">
      您尚未登录，请<span @click.stop="tologin">登录</span>后下载本次推荐的简历
    </div>
    <div class="lqzc">
      <div @click="show=true">领取福利</div>
      <div @click="toregist">马上注册开通</div>
    </div>
    <div class="jsc">
      <div>
        *首次注册用户，请在完成“企业认证”提交后，联系客服获取免费体验金；
      </div>
      <div>
        *尚未注册用户，请在注册后及完成企业认证后，至“我的收藏”内下载本次推荐的简历。
      </div>
    </div>
    <div class="buclass">
      <div class="bu1"><img src="../../assets/msg1.png" alt="" /></div>
      <div class="bu2"><img src="../../assets/msg2.png" alt="" /></div>
      <div class="bu3">
        2021平躺赚外快，高效做兼职，升级城市代理人，收益无上限，限时招募，赶快联系客服加入吧！
      </div>
      <div class="kfc">
        <img src="../../assets/kf2.png" alt="" />
        <div @click="clickkefu">有问题，联系客服</div>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="topmsg">
        <div><div></div><van-icon @click="show=false" size=".39rem" name="cross" /></div>
        <div>添加客服微信，领取免费体验券</div>
        <textarea @click="copymsg" readonly="readonly" v-model="luobomsg.WXcode" name="" id="textid" rows="1"></textarea>
        <div>点击复制微信号，再打开微信添加</div>
      </div>
    </van-popup>
    <div v-show="show2" class="bzclass">
        <div class="xone"><div></div><van-icon @click.stop="show2=false" size=".39rem" name="cross" /></div>
        <div class="bzcl4">有什么可以帮您吗？</div>
        <div class="bzcl2">
            <div class="bzcl3">
                <van-icon style="margin-right:.1rem" color="#FD751E" size=".39rem" name="phone-o" />
                <div><a :href="luobomsg.PHONE">电话咨询</a></div>
            </div>
            <div class="bzcl3">
                <van-icon style="margin-right:.1rem" color="#FD751E" size=".39rem" name="chat-o" />
                <div @click="show = true;">在线咨询</div>
            </div>
            <div class="bzcl3">
                <van-icon style="margin-right:.1rem" color="#FD751E" size=".39rem" name="manager-o" />
                <div @click="tologin">去登录</div>
            </div>
        </div>
    </div>
    <img @click="show2=!show2" class="downimgclass2" src="../../assets/download.png" alt="">
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import islogin from "../../../util/islogin";
import transfer from "../../../util/transfer";
import base from "../../../util/base";
import luobomsg from "../../../util/luobomsg";
import share_resume from "../../../util/share_resume";
import kefu from "../../components/kefu"
export default {
  components:{
    kefu
  },
  data() {
    return {
      sharemm: "",
      show: false,
      show2:true,
    };
  },
  beforeCreate() {
    if (!islogin()) {
      //未登录状态
    }
  },
  created() {
    this.luobomsg = luobomsg;
    this.sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (
      this.sharemm != "" &&
      this.sharemm != undefined &&
      this.sharemm.urlsharees
    ) {
    } else {
    }
  },
  methods: {
    tologin() {
      share_resume("login");
    },
    toregist() {
      share_resume("register");
    },
    copymsg(){
        let textareamsg = document.getElementById("textid");
        textareamsg.select();
        document.execCommand("copy");
        window.getSelection().empty();
        this.$toast("复制成功");
        textareamsg = null;
    },
    clickkefu() {
      this.$refs.kefuid.showmeth();
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.xone{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.downimgclass2{
    position: fixed;
    bottom: 1rem;
    right: 0;
    width: 1rem;
}
.bzcl4{
    text-align: center;
    font-size: .3rem;
    margin-bottom: .3rem;
    margin-top: -.2rem;
}
.bzcl2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bzcl3{
    display: flex;
    align-items: center;
    margin-right: .2rem;
    font-size: .28rem;
}
.bzclass{
    position: fixed;
    padding: .2rem;
    border-radius: .2rem;
    bottom: 1rem;
    left: .3rem;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgb(214 210 210 / 50%);
    color: #576170;
}
.topmsg>div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topmsg>div:nth-child(2){
    font-size: .3rem;
    font-weight: bold;
     padding-top: .1rem;
}
.topmsg>:nth-child(3){
    margin: .3rem 0;
    text-align: center;
    width: 100%;
    border: none;
}
.allmsgclass >>> .van-popup--center{
    border-radius: .3rem;
    padding: .3rem;
    width: 65%;
}
.topmsg div{
    text-align: center;
}
.kfc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.26rem;
  line-height: 0.36rem;
  color: #ffdb80;
}
.kfc img {
  width: 0.38rem;
  margin-right: 0.14rem;
}
.bu3 {
  margin-top: 0.12rem;
  padding: 0.24rem 0.4rem;
  background-image: url("../../assets/hua.png");
  background-size: 100% 100%;
  font-size: 0.26rem;
  line-height: 0.36rem;
  color: #ffffff;
}
.bu2 {
  font-size: 0.42rem;
  line-height: 0.5rem;
  color: #ffff81;
  text-align: center;
  margin-top: 0.08rem;
}
.bu2 img {
  width: 100%;
  width: 3.28rem;
  height: 0.5rem;
}
.bu1 {
  font-size: 0.72rem;
  line-height: 0.86rem;
  text-align: center;
  color: #ffffff;
}
.bu1 img {
  width: 100%;
  height: 0.86rem;
  width: 5.04rem;
}
.buclass {
  background-color: #ee003b;
  padding: 0.42rem 0.4rem;
}
.jsc {
  margin: 0.4rem 0.86rem;
  font-size: 0.24rem;
  line-height: 0.38rem;
  color: #909399;
  margin-bottom: 1.06rem;
}
.lqzc {
  font-size: 0.36rem;
  line-height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.38rem;
}
.lqzc > div:first-child {
  color: #af4402;
  padding: 0.3rem 0.62rem;
  padding-bottom: 0.4rem;
  text-align: center;
  background-image: url("../../assets/ye.png");
  background-size: 100% 100%;
  margin-right: 0.2rem;
}
.lqzc > div:nth-child(2) {
  color: #ffffff;
  padding: 0.3rem 0.62rem;
  padding-bottom: 0.4rem;
  text-align: center;
  background-image: url("../../assets/re.png");
  background-size: 100% 100%;
}
.tc {
  text-align: center;
  color: #303133;
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.22rem;
}
.tc span {
  color: #4d88ff;
  text-decoration: underline;
}
.downimgclass {
  margin-top: .3rem;
  width: 100%;
  text-align: center;
}
/* @media only screen and (min-height: 800px) {
  .downimgclass {
    margin-top: 35%;
    width: 100%;
    text-align: center;
  }
} */
.downimgclass img {
  width: 4.54rem;
}
.allmsgclass {
  min-height: 100%;
  background-color: #ffffff;
}
</style>